import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby'
import { Container, TagList, Tag } from './tagSection.css';
const TagSection = ({ tags, children, type, as = '', }) => {
    console.log("type: ", type)
    console.log("tags", tags)
    return (
        <Container>
            <TagList>
                {tags !== null && tags !== undefined ? tags.map(tag => {
                    return (
                        <li key={tag.fieldValue}>
                            <Tag to={`/${type}/tags/${tag.fieldValue.toLowerCase()}`}>
                                {tag.fieldValue}
                            </Tag>
                        </li>
                    )
                }) : <div />}
            </TagList>
        </Container >
    );
};

TagSection.propTypes = {
    as: PropTypes.string,
    size: PropTypes.oneOf(['large']),
};

export default TagSection;
