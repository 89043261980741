import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates';


export const Container = styled.div`
// background: lightblue;
margin-left: 0rem;
margin-top: 0;
overflow: scroll;


${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
  overflow: hidden;

  `};

  ${MEDIA.M_PHONE`

  `};

  ${MEDIA.S_PHONE`

  `};

`

export const ArticleCardList = styled.ul`


background: transparent;
width: 100%;
height: auto;
padding-top: 5rem;
padding-bottom: 15rem;


${MEDIA.L_TABLET`
padding-top: 6rem;
  `};

${MEDIA.TABLET`
padding-bottom: 7.5rem;
padding-top: 7.5rem;
  `};


  ${MEDIA.PHONE`
  padding-bottom: 1rem;
  padding-top: 1.5rem;
  margin-top: 6rem;
  `};

  ${MEDIA.M_PHONE`
  `};

  ${MEDIA.S_PHONE`
padding-bottom: 0rem;
  `};

`

