import React from 'react';
import PropTypes from 'prop-types';
import { FeatureStatementStyle, HeroImage, FeatureTitle } from './statement.css';

const FeatureStatement = ({ children, imageType, type, cover, color, title, as = '', size }) => {
    return (
        <FeatureStatementStyle bgImg={imageType === "gif" ? cover : cover ? cover.src : null} type={type} color={color}>
            {/* <HeroImage fluid={cover} alt="feature story" /> */}
            <FeatureTitle>{title}</FeatureTitle>
        </FeatureStatementStyle>
    );
};

FeatureStatement.propTypes = {
    as: PropTypes.string,
    size: PropTypes.oneOf(['large']),
};

export default FeatureStatement;