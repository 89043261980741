import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates';
import Img from 'gatsby-image'
import { blue, orange, grey, } from "../../../constants/theme"


export const Container = styled.div`
width: 90%;
height: 27.5rem;
background: white;
margin: 2.5%;
margin-top: 5%;
margin-left: 1.25rem;
box-shadow: 1px 2px 6px 1px rgba(0,0,0,0.25);
border: 1px solid lightgrey;
display: flex;
position: relative;
border-radius: 1.5px;
transition: .25s ease-in-out;
max-width: 1000px;
&:hover {
    // background: rgba(0,0,0,0.1);
    box-shadow: 1px 3px 10px 1px rgba(0,0,0,0.35);
transform: translate(-10px, -5px);
}

z-index: 10000;

${MEDIA.L_TABLET`
height: 25rem;
  `};

${MEDIA.TABLET`
height: 17.5rem;
width: 95%

  `};


  ${MEDIA.PHONE`
height: 15rem;
margin-left: 0.75rem;
width: 95%;
&:hover {
  // background: rgba(0,0,0,0.1);
  box-shadow: 1px 3px 10px 1px rgba(0,0,0,0.35);
transform: translate(-2.5px, -10px);
}
  `};

  ${MEDIA.M_PHONE`

  `};

  ${MEDIA.S_PHONE`

  `};

// ${MEDIA.TABLET`
// // top: 1rem;

//   `};


//   ${MEDIA.PHONE`
// margin: 0 auto;
// font-size: 1.25rem;
// line-height: 1.5rem;
// letter-spacing: .33px;
  `};

`
export const Line = styled.hr`
width: 2px;
height: 90%;
top: 10%;
left: 50%;
background: lightgrey;
position: static;


${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`

  `};

  ${MEDIA.M_PHONE`
  position: absolute;
  width: 1px;
top: 0;
height: 85%;
  `};

  ${MEDIA.S_PHONE`

  `};
`

export const Left = styled.div`
width: 50%;
height: 100%;
// background: red;
left: 0;
display: flex;
align-items: center;
justify-content: center;
// position: relative;

${MEDIA.PHONE`
width: 47.5%
`};

${MEDIA.M_PHONE`

`};

`


export const Right = styled.div`
width: 50%;
height: 100%;
// background: blue;
left: 50%;
display: flex;
align-items: center;
justify-content: center;
// position: relative;

${MEDIA.PHONE`
width: 47.5%

`};

${MEDIA.M_PHONE`

`};

`

// Media Left hand side

export const MediaContainer = styled.div`
width: 95%;
height: 95%;
margin: 2.5%;
border-radius: 5px;
background: lightgrey;
// position: absolute;
z-index: 3;
overflow: hidden;
`

export const TagList = styled.div`
position: absolute;
z-index: 100;
// background: red;
display: flex;
list-style: none;
left: 0;
top: .75rem;
width: auto;
height: auto;
margin-left: .75rem;
${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`

  `};

  ${MEDIA.M_PHONE`

  `};

  ${MEDIA.S_PHONE`

  `};

`

export const Tag = styled.div`
font-size: 1rem;
font-weight: 600;
color: white;
display: inline-block;
margin-left: .5rem;
padding: .1rem;
z-index: 5;
// top: 17.5rem;
right: 1.5rem;
position: static;
writing-mode: vertical-rl;
text-orientation: mixed;
text-transform: uppercase;
letter-spacing: .2rem;
font-family: 'Termina';
height: auto;
text-shadow: 2px 2px ${blue};
padding: .75px;
// border: .5px  solid rgba(2, 205, 252, 0.5);
filter: drop-shadow(2px 2px 5pxd rgba(2, 205, 252, 0.33));
// border: 2.5px solid rgba(2, 205, 252, 0.25);
border-radius: 2.5px;


${MEDIA.PHONE`
text-shadow: 1.5px 1.5px ${blue};

`};

${MEDIA.M_PHONE`
font-size: .66rem;
// box-shadow: 2px 2px 5px 2.5px rgba(2, 205, 252, 0.25);
padding-left: .1rem;
margin-right: .25rem;
margin-left: 0;
  `};
`


export const PreviewImage = styled(Img)`
width: 100%;
height: 100%;
border-radius: 1px;
object-fit: cover;
position: absolute;
`

export const PreviewGif = styled.img`
width: 100%;
height: 100%;
border-radius: 1px;
object-fit: cover;
// position: absolute;
overflow: hidden;

${MEDIA.PHONE`
height: 115%;
margin-top: -7.5%;
  `};
`

export const PreviewCarousel = styled.img`

`
export const PreviewVideo = styled.img`

`

// Text right hand side 

export const TextContainer = styled.div`
width: 95%;
height: 95%;
margin: 2.5%;
border-radius: 1.5px;
background: white;
display: block;
position: relative;
z-index: 3;
`

export const Title = styled.h1`
font-size: 90%;
font-family: "Termina";
color: ${orange};
font-weight: 800;
// background: blue;
width: 75%;

${MEDIA.L_TABLET`
font-size: 80%;
width: 75%;
  `};

${MEDIA.TABLET`
font-size: 60%;
width: 90%;

  `};


  ${MEDIA.PHONE`
  width: 100%;
font-size: 50%;
  `};

  ${MEDIA.M_PHONE`
  font-size: 1.15rem;
width: 110%;
  `};

  ${MEDIA.S_PHONE`
font-size: 1.05rem;
margin-top: 0;
  `};

`



export const Date = styled.p`
font-size: 1.5rem;
font-weight: 700;
margin-top: 0.5rem;
margin-bottom: 1rem;
font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
color: ${blue};
top: 0;

${MEDIA.L_TABLET`
font-size: 1.4rem;

  `};

${MEDIA.TABLET`
font-size: 1.3rem;

  `};


  ${MEDIA.PHONE`
font-size: 1.15rem;
  `};

  ${MEDIA.M_PHONE`
  font-size: 1rem;

  `};

  ${MEDIA.S_PHONE`
font-size: 0.9rem;
  `};
`

export const SummaryBox = styled.div`
width: 90%;
height: 50%;
// background: pink;
top: 47.5%;
bottom: 0;
position: absolute;

${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`

  `};

  ${MEDIA.M_PHONE`
top: 45%;
width: 97.5%;

  `};

  ${MEDIA.S_PHONE`
top: 42.5%;
  `};
`

export const Summary = styled.p`
font-size: 1.75rem;
text-transform: none;
line-height: 2rem;
left: 0;
width: 100%;
margin-bottom: 0rem;
font-weight: 500;
font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
color: grey;

${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
font-size: 1.5rem;
  `};


  ${MEDIA.PHONE`
font-size: 1.25rem;
margin-top: 2.5%;

  `};

  ${MEDIA.M_PHONE`
  font-size: 0.95rem;
line-height: 1.15rem;
  `};

  ${MEDIA.S_PHONE`
line-height: 1rem;
font-size: 0.8rem;
  `};
`

