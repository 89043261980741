
import React from 'react';
import PropTypes from 'prop-types';
import {
    Container, ArticleCardList
} from './articleList.css';
import ArticleCard from '../articleCard/articleCard'


const ArticleList = ({ children, posts }) => {
    console.log("posts", posts)
    return (
        <Container>
            <ArticleCardList>
                {posts.map((post, i) => {
                    return (
                        <ArticleCard post={post} key={i} />
                    )
                })}
            </ArticleCardList>
        </Container>

    );
};



export default ArticleList;