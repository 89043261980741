import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import CategoryStatement from '../components/Statement/categoryStatement'
import { graphql, Link } from 'gatsby';
import Section from '../components/Section/section'
import { MainSectionContainer } from '../components/Section/section.css'
import FeatureStatement from "../components/Statement/featureStatement"
import TagSection from '../components/Section/tagSection/tagSection'
import CardList from '../components/article/articleList/articleList'
import Typeform from '../components/typeform/typeform'
import TypeformCTA from '../components/typeform/typeformCTA/typeformCTA'



const Audio = ({ data }) => {
  console.log("data ", data)
  const tags = data.tagsGroup.group
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const posts = data.allMarkdownRemark.edges
  console.log("url", url)
  const postsPerPage = 9
  let featured;
  const postsWithoutFeatured = posts.filter(({ node }) => {
    if (node.frontmatter.featured) {
      featured = node
    }
    return !node.frontmatter.featured
  })
  const numPages = Math.ceil(postsWithoutFeatured.length / postsPerPage)
  const subheader = "Discussions, \n interviews \n  and conversations about crypto, Web3 and blockchain."

  const SEO = {
    pageInfo: "Ntheos Mag | Audio Content"
  }

  return (
    <Layout seo={SEO}>
      <CategoryStatement category="audio" subheader={subheader} />
      <Section section='tags' header bg='white' heading='' size='auto'>
        <TagSection type="audio" tags={tags} />
      </Section>
      {/* {featured && featured !== undefined && <Section section='featured' size='medium'>
        <Link to={`${featured.fields.slug}`}>
        </Link>
        <MainSectionContainer type="category" />
      </Section>} */}

      <Section bg='white' section="latest" size='auto'>
        <CardList posts={postsWithoutFeatured} />
      </Section>

    </Layout>
  )
};

Audio.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Audio;

export const query = graphql`
 query AudioPageQuery {
  tagsGroup: allMarkdownRemark(
    filter: {frontmatter: {type: {eq: "pod"}}}
  ) {
    group(field: frontmatter___tags) {
      totalCount
      fieldValue
    }
  }
  allMarkdownRemark(
    sort: {order: DESC, fields: frontmatter___date}
    filter: {frontmatter: {type: {eq: "pod"}}}) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          type
          description
          date(formatString: "ddd MMMM Do, YYYY")
          tags
          opener
          featured
          cover {
            childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          }
        }
      }
    }
  }
}
`;