import React from 'react';
import {
    Container, Left, MediaContainer, Right, TextContainer, Date, Title, Summary,
    PreviewImage, PreviewCarousel, PreviewVideo, SummaryBox, Line, TagList, Tag, PreviewGif
} from './articleCard.css';
import Link from 'gatsby-link'
import { useEffect } from 'react'
import PodIcon from '../../../images/pod-icon.png'


const ArticleCard = ({ children, post }) => {
    console.log("in card ", post.node)
    const postData = post.node.frontmatter
    const tags = post.node.frontmatter.tags
    let date;

    return (
        <Link to={`${post.node.fields.slug}`} >
            <Container>

                <Left>

                    <MediaContainer>
                        {tags !== null && tags !== undefined ?
                            <TagList>
                                {tags[0] !== null && tags[0] !== undefined ? <Tag> {tags[0]}</Tag> : <div style={{ display: 'none' }} />}
                                {tags[1] !== null && tags[1] !== undefined ? <Tag> {tags[1]}</Tag> : <div style={{ display: 'none' }} />}
                                {tags[2] !== null && tags[2] !== undefined ? <Tag> {tags[2]}</Tag> : <div style={{ display: 'none' }} />}
                            </TagList> : ''}

                        {postData.coverGif !== null && postData.coverGif !== undefined ?
                            <PreviewGif src={postData.coverGif} /> :
                            postData.cover !== null && postData.cover !== undefined ?
                                <PreviewImage fluid={postData.cover.childImageSharp.fluid} /> :
                                postData.type === 'pod' ?
                                    <PreviewGif src={PodIcon} /> :
                                    postData.type === 'vid' ?
                                        <PreviewGif src={''} /> : null

                        }
                    </MediaContainer>
                </Left>
                <Line />
                <Right>
                    <TextContainer>
                        <Date>{postData.date}</Date>
                        <Title>{postData.title}</Title>
                        <SummaryBox>
                            <Summary>{postData.description}</Summary>
                        </SummaryBox>
                    </TextContainer>
                </Right>
            </Container>
        </Link >
    );
};


export default ArticleCard;