import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { Link } from 'gatsby'

// container
export const Container = styled.div`
// background: red;
height: 100%;
width: 100%;

${MEDIA.TABLET`

  `};

  ${MEDIA.L_TABLET`

  `};

  ${MEDIA.PHONE`

  `};

  ${MEDIA.M_PHONE`

  `};

  ${MEDIA.S_PHONE`

  `};

`
// tag list
export const TagList = styled.ul`
// background: blue;
height: 100%;
margin-top: 10rem;
margin-left: 1rem;
width: 100%;
height: 75%;
display: flex;
// align-items: center;
flex-wrap: wrap;
max-width: 1200px;


li {
    left: 0rem;
    margin-top: 1rem;
    margin-bottom: 0;
    position: static;
    margin-right: 2rem;
}
${MEDIA.L_TABLET`

`};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
  margin-top: 7rem;
margin-bottom: 0rem;
margin-left: 0rem;

  li {
   
  }
  `};

  ${MEDIA.M_PHONE`
margin-top: 8rem;
li {
   margin-top: .75rem;
   margin-right: 1.25rem;
}
  `};

  ${MEDIA.S_PHONE`

  `};
`
// Tags
export const Tag = styled(Link)`
// background: rgba(0,0,0, 0.25);
border: 1px solid lightgrey;
box-shadow: 1px 1px 2px 1px rgba(0,0,0, 0.25);
padding: 5px;
margin: 2.5rem;
margin-top: 3rem;
line-height: 5rem;
color: grey;
height: 100%;
width: 100%;
text-decoration: none;
font-family: "Termina";
font-weight: 600;
font-size: 2.25rem;
transition: .25s ease-in-out;

&:hover, &:active {
    background: rgba(2, 205, 252, 0.35);
    box-shadow: 2px 2px 10px 3px rgba(2, 205, 252, 0.5);
    border: 5px solid rgba(2, 205, 252, 0.25);
    border-radius: 10px;
}

${MEDIA.L_TABLET`
font-size: 2rem;
`};

${MEDIA.TABLET`
font-size: 1.75rem;
margin: 1rem;
line-height: 3.5rem;
  `};

 

  ${MEDIA.PHONE`
  font-size: 1.25rem;

  `};

  ${MEDIA.M_PHONE`
  margin: 1rem;
  padding: 2.5px;

  line-height: 2.5rem;
  `};

  ${MEDIA.S_PHONE`

  `};
`

